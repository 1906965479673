import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Container from '~components/Container';
import RichText from '~components/RichText';
import { generateUrlFriendlyIdFromTitle } from '~utils/url-helper';
import Resource from './Resource';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color,
    position: 'relative',
    paddingTop: '72px',
    paddingBottom: '72px',
    scrollMarginTop: '100px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '32px',
      paddingBottom: '32px'
    }
  },
  title: {
    width: '100%',
    marginBottom: '53px',
    '& > *': {
      maxWidth: '700px',
      margin: 'auto',
      color: 'black',
      fontSize: 40,
      fontFamily: 'Inter',
      fontWeight: '600',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        textAlign: 'left'
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '36px'
    }
  },
  resources: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    paddingTop: '36px',
    borderTop: '1px #D4D4D4 solid'
  }
}));

const ArticleResources = ({ section_title, resources, background_color, containerSize }) => {
  const location = useLocation();
  const classes = useStyles({ background_color });
  const sectionId = useMemo(
    () => generateUrlFriendlyIdFromTitle(section_title?.text),
    [section_title]
  );

  useEffect(() => {
    // expand when url hash has a section id
    if (location?.hash && sectionId === location.hash.substring(1)) {
      navigate(location?.hash);
    }
  }, [location?.hash, sectionId]);

  return (
    <div className={classes.root} id={sectionId}>
      <Container maxWidth={containerSize}>
        <RichText externalClassName={classes.title} html={section_title?.html} />
        <div className={classes.resources}>
          {resources.map(item => (
            <Resource key={item?.resource?.uid} {...item} />
          ))}
        </div>
      </Container>
    </div>
  );
};

ArticleResources.propTypes = {
  background_color: PropTypes.string,
  section_title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      custom_title: PropTypes.string,
      custom_duration: PropTypes.string,
      custom_description: PropTypes.shape({
        text: PropTypes.string.isRequired,
        html: PropTypes.string.isRequired
      }),
      resource: PropTypes.shape({
        uid: PropTypes.string,
        url: PropTypes.string
      })
    })
  ),
  containerSize: PropTypes.string
};

ArticleResources.defaultProps = {
  resources: [],
  background_color: '#FFFFFF',
  containerSize: 'md'
};

export default ArticleResources;
