import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getResourceTypeIcon } from '~utils/resource-helpers';

const useStyles = makeStyles({
  resourceInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gridGap: '8px',
    justifyContent: 'start'
  },
  infoText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: props => (props.size ? `${props.size}px` : '14px'),
    color: '#747474',
    marginBottom: 0,
    marginTop: 0,
    lineHeight: props => (props.size ? `${props.size}px` : '14px')
  },
  icon: {
    height: props => (props.size ? `${props.size}px` : '14px')
  }
});

const ResourceMeta = ({ resourceType, metaText, size }) => {
  const classes = useStyles({ size });
  const typeIcon = getResourceTypeIcon(resourceType);

  return (
    <div className={classes.resourceInfo}>
      <img className={classes.icon} src={typeIcon} alt="icon" />
      <p className={classes.infoText}>{metaText}</p>
    </div>
  );
};

ResourceMeta.propTypes = {
  resourceType: PropTypes.string.isRequired,
  metaText: PropTypes.string,
  size: PropTypes.number
};

ResourceMeta.defaultProps = {
  metaText: null,
  size: 14
};

export default ResourceMeta;
